'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var ExternalPhoto$Paulshen = require("../components/ExternalPhoto.bs.js");

var grid = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.margin2(Css.zero, Css.px(-16)),
        /* [] */0
      ]
    ]);

var column = Css.style(/* :: */[
      Css.padding2(Css.zero, Css.px(16)),
      /* :: */[
        Css.boxSizing(Css.borderBox),
        /* [] */0
      ]
    ]);

var column2 = Css.style(/* :: */[
      Css.width(Css.pct(50)),
      /* [] */0
    ]);

var column3 = Css.style(/* :: */[
      Css.width(Css.pct(33.333333)),
      /* [] */0
    ]);

var column4 = Css.style(/* :: */[
      Css.width(Css.pct(25)),
      /* [] */0
    ]);

var column5 = Css.style(/* :: */[
      Css.width(Css.pct(20)),
      /* [] */0
    ]);

var item = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* :: */[
        Css.transition(200, undefined, undefined, "opacity"),
        /* :: */[
          Css.hover(/* :: */[
                Css.opacity(0.9),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  grid: grid,
  column: column,
  column2: column2,
  column3: column3,
  column4: column4,
  column5: column5,
  item: item
};

function groupIntoColumns(items, numColumns) {
  var columns = /* array */[];
  for(var _for = 0 ,_for_finish = numColumns - 1 | 0; _for <= _for_finish; ++_for){
    columns.push(/* array */[]);
  }
  items.forEach((function (item, i) {
          Caml_array.caml_array_get(columns, Caml_int32.mod_(i, numColumns)).push(item);
          return /* () */0;
        }));
  return columns;
}

var Unsupported = Caml_exceptions.create("PhotoGrid-Paulshen.Unsupported");

function PhotoGrid(Props) {
  var photos = Props.photos;
  var photoFiles = Props.photoFiles;
  var numColumns = Props.numColumns;
  var onClick = Props.onClick;
  var columns = groupIntoColumns(photos, numColumns);
  return React.createElement("div", {
              className: grid
            }, columns.map((function (column$1, i) {
                    var tmp;
                    switch (numColumns) {
                      case 2 :
                          tmp = column2;
                          break;
                      case 3 :
                          tmp = column3;
                          break;
                      case 4 :
                          tmp = column4;
                          break;
                      case 5 :
                          tmp = column5;
                          break;
                      default:
                        throw Unsupported;
                    }
                    return React.createElement("div", {
                                key: String(i),
                                className: Cn.make(/* :: */[
                                      column,
                                      /* :: */[
                                        tmp,
                                        /* [] */0
                                      ]
                                    ])
                              }, column$1.map((function (src, i) {
                                      return React.createElement("div", {
                                                  key: String(i),
                                                  className: item,
                                                  onClick: (function (param) {
                                                      return Curry._1(onClick, src);
                                                    })
                                                }, React.createElement(ExternalPhoto$Paulshen.make, {
                                                      data: photoFiles,
                                                      url: src,
                                                      minAspectRatio: 0.8,
                                                      maxAspectRatio: 1.4
                                                    }));
                                    })));
                  })));
}

var make = PhotoGrid;

exports.Styles = Styles;
exports.groupIntoColumns = groupIntoColumns;
exports.Unsupported = Unsupported;
exports.make = make;
/* grid Not a pure module */
