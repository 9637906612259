'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Gatsby = require("gatsby");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Grid$Paulshen = require("../components/Grid.bs.js");
var Page$Paulshen = require("../ui/Page.bs.js");
var Text$Paulshen = require("../ui/Text.bs.js");
var Fonts$Paulshen = require("../constants/Fonts.bs.js");
var Layer$Paulshen = require("../components/Layer.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");
var Gatsby$Paulshen = require("../components/Gatsby.bs.js");
var PhotoGrid$Paulshen = require("../ui/PhotoGrid.bs.js");
var MediaQuery$Paulshen = require("../constants/MediaQuery.bs.js");
var PhotoOverlay$Paulshen = require("../ui/PhotoOverlay.bs.js");
var WindowDimensions$Paulshen = require("../components/WindowDimensions.bs.js");

var topSection = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var body = Css.style(/* :: */[
      Css.maxWidth(Css.px(896)),
      /* :: */[
        Css.margin3(Css.zero, Css.auto, Css.px(128)),
        /* :: */[
          MediaQuery$Paulshen._840(/* :: */[
                Css.marginBottom(Css.px(64)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var text = Css.style(/* :: */[
      Css.maxWidth(Css.px(480)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var equipment = Css.style(/* [] */0);

var grid = Css.style(/* :: */[
      Css.display(Css.grid),
      /* :: */[
        Css.gridTemplateColumns(/* :: */[
              /* `repeat */[
                108828507,
                /* tuple */[
                  /* `num */[
                    5496390,
                    3
                  ],
                  Css.fr(1)
                ]
              ],
              /* [] */0
            ]),
        /* :: */[
          MediaQuery$Paulshen._840(/* :: */[
                Css.gridTemplateColumns(/* :: */[
                      /* `repeat */[
                        108828507,
                        /* tuple */[
                          /* `num */[
                            5496390,
                            2
                          ],
                          Css.fr(1)
                        ]
                      ],
                      /* [] */0
                    ]),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  topSection: topSection,
  body: body,
  text: text,
  equipment: equipment,
  grid: grid
};

var image = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* [] */0
    ]);

var title = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary200),
      /* :: */[
        Css.fontSize(Css.px(20)),
        /* :: */[
          Css.fontWeight(Css.medium),
          /* :: */[
            Css.display(Css.inlineBlock),
            /* :: */[
              Css.marginBottom(Css.px(8)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var yearUnit = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary300),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.marginTop(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var dateLabel = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary400),
      /* :: */[
        Css.fontWeight(Css.light),
        /* [] */0
      ]
    ]);

var date = Css.style(/* :: */[
      Css.fontFamily(Fonts$Paulshen.mono),
      /* [] */0
    ]);

var unit = Css.style(/* :: */[
      Css.border(Css.px(1), Css.solid, Colors$Paulshen.primary450),
      /* :: */[
        Css.padding(Css.px(16)),
        /* :: */[
          Css.borderTopWidth(Css.zero),
          /* :: */[
            Css.borderLeftWidth(Css.zero),
            /* :: */[
              Css.nthChild(/* `add */[
                    4846113,
                    /* tuple */[
                      -1,
                      3
                    ]
                  ], /* :: */[
                    Css.borderTopWidth(Css.px(1)),
                    /* [] */0
                  ]),
              /* :: */[
                Css.nthChild(/* `add */[
                      4846113,
                      /* tuple */[
                        3,
                        1
                      ]
                    ], /* :: */[
                      Css.borderLeftWidth(Css.px(1)),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.textDecoration(Css.none),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.backgroundColor(Colors$Paulshen.primary490),
                          /* :: */[
                            Css.selector(" ." + title, /* :: */[
                                  Css.textDecoration(Css.underline),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]),
                    /* :: */[
                      MediaQuery$Paulshen._840(/* :: */[
                            Css.nthChild(/* `add */[
                                  4846113,
                                  /* tuple */[
                                    -1,
                                    3
                                  ]
                                ], /* :: */[
                                  Css.borderTopWidth(Css.zero),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.nthChild(/* `add */[
                                    4846113,
                                    /* tuple */[
                                      3,
                                      1
                                    ]
                                  ], /* :: */[
                                    Css.borderLeftWidth(Css.zero),
                                    /* [] */0
                                  ]),
                              /* :: */[
                                Css.nthChild(/* `add */[
                                      4846113,
                                      /* tuple */[
                                        -1,
                                        2
                                      ]
                                    ], /* :: */[
                                      Css.borderTopWidth(Css.px(1)),
                                      /* [] */0
                                    ]),
                                /* :: */[
                                  Css.nthChild(/* `add */[
                                        4846113,
                                        /* tuple */[
                                          2,
                                          1
                                        ]
                                      ], /* :: */[
                                        Css.borderLeftWidth(Css.px(1)),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles$1 = {
  image: image,
  title: title,
  yearUnit: yearUnit,
  dateLabel: dateLabel,
  date: date,
  unit: unit
};

function PhotosPage$AlbumUnit(Props) {
  var album = Props.album;
  return React.createElement(Gatsby.Link, {
              to: "/albums/" + album.slug,
              children: null,
              className: unit,
              key: album.id
            }, album.photoFiles.length > 0 ? React.createElement(Gatsby$Paulshen.$$Image.make, {
                    data: Caml_array.caml_array_get(album.photoFiles, 0).childImageSharp,
                    minAspectRatio: 0.8,
                    className: image
                  }) : null, React.createElement("div", {
                  className: title
                }, album.title), React.createElement("div", {
                  className: yearUnit
                }, React.createElement(Text$Paulshen.MonoHeader.make, {
                      children: "Date",
                      tag: /* H4 */16108,
                      className: dateLabel
                    }), React.createElement("div", {
                      className: date
                    }, album.date)));
}

var AlbumUnit = {
  Styles: Styles$1,
  make: PhotosPage$AlbumUnit
};

var Unexpected = Caml_exceptions.create("PhotosPage-Paulshen.Unexpected");

function PhotosPage(Props) {
  var data = Props.data;
  console.log(data);
  var windowWidth = React.useContext(WindowDimensions$Paulshen.context);
  var match = React.useState((function () {
          return ;
        }));
  var setSelectedPhoto = match[1];
  var selectedPhoto = match[0];
  var match$1 = windowWidth > 840;
  var tmp;
  if (selectedPhoto !== undefined) {
    var selectedPhoto$1 = selectedPhoto;
    tmp = React.createElement(Layer$Paulshen.make, {
          children: React.createElement(PhotoOverlay$Paulshen.make, {
                thumbnails: data.file.thumbnails,
                images: data.file.full,
                startingIndex: data.file.full.findIndex((function (file) {
                        return file.relativePath === selectedPhoto$1;
                      })),
                onClose: (function (param) {
                    return Curry._1(setSelectedPhoto, (function (param) {
                                  return ;
                                }));
                  })
              })
        });
  } else {
    tmp = null;
  }
  return React.createElement(Page$Paulshen.make, {
              children: null,
              title: "Photos"
            }, React.createElement("div", {
                  className: body
                }, React.createElement(Text$Paulshen.Header.make, {
                      children: "Photography",
                      size: /* S3 */18560,
                      tag: /* H1 */16105
                    }), React.createElement(Grid$Paulshen.Root.make, {
                      className: topSection,
                      children: null
                    }, React.createElement(Grid$Paulshen.Cell.make, {
                          span: 8,
                          children: React.createElement(Text$Paulshen.Body.make, {
                                children: React.createElement("p", undefined, "I really like my Fujifilm camera but don't use it much outside trips. It's probably not long before the phone camera gets good enough that the convenience wins over carrying extra equipment."),
                                className: text
                              }),
                          fullWidthOnMobile: true
                        }), React.createElement(Grid$Paulshen.Cell.make, {
                          span: 4,
                          children: null,
                          fullWidthOnMobile: true
                        }, React.createElement(Text$Paulshen.MonoHeader.make, {
                              children: "Equipment",
                              tag: /* H3 */16107
                            }), React.createElement("div", {
                              className: equipment
                            }, React.createElement(Text$Paulshen.Body.make, {
                                  children: "FUJIFILM XT-1"
                                }), React.createElement(Text$Paulshen.Body.make, {
                                  children: "FUJIFILM XF 23mm f/1.4"
                                }), React.createElement(Text$Paulshen.Body.make, {
                                  children: "FUJIFILM XF 14mm f/2.8"
                                })))), React.createElement(Text$Paulshen.MonoHeader.make, {
                      children: "Albums",
                      tag: /* H2 */16106
                    }), React.createElement("div", {
                      className: grid
                    }, data.allAlbumsJson.nodes.map((function (album) {
                            return React.createElement(PhotosPage$AlbumUnit, {
                                        album: album,
                                        key: album.id
                                      });
                          })))), React.createElement(Text$Paulshen.MonoHeader.make, {
                  children: "Photos",
                  tag: /* H2 */16106
                }), React.createElement(PhotoGrid$Paulshen.make, {
                  photos: data.file.thumbnails.map((function (image) {
                          return /* Local */Block.__(1, [image.relativePath]);
                        })),
                  photoFiles: data.file.thumbnails,
                  numColumns: match$1 ? 4 : 2,
                  onClick: (function (src) {
                      if (src.tag) {
                        var relativePath = src[0];
                        return Curry._1(setSelectedPhoto, (function (param) {
                                      return relativePath;
                                    }));
                      } else {
                        throw Unexpected;
                      }
                    })
                }), tmp);
}

var frontmatter = {
  externalImages: /* array */[
    "photos/iceland/DSCF4104.jpg",
    "photos/point-reyes/DSCF2035.jpg",
    "photos/lost-coast/DSCF5247.jpg",
    "photos/iceland/DSCF4068.jpg",
    "photos/iceland/DSCF3992.jpg",
    "photos/iceland/DSCF3954.jpg",
    "photos/catalina/DSCF6119.jpg",
    "photos/iceland/DSCF3933.jpg",
    "photos/iceland/DSCF3893.jpg",
    "photos/lost-coast/DSCF5262.jpg",
    "photos/costa-rica/DSCF4316.jpg",
    "photos/costa-rica/DSCF4390.jpg"
  ]
};


const {graphql} = require('gatsby');
exports.query = graphql`
  query AlbumsPage($filePath: String) {
    allAlbumsJson(sort: {fields: date, order: DESC}) {
      nodes {
        id
        date(formatString: "YYYY_MM")
        title
        slug
        photoFiles {
          relativePath
          childImageSharp {
            fluid(maxWidth: 120, quality: 70) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
    file(absolutePath: { eq: $filePath }) {
      thumbnails: externalImages {
        relativePath
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      full: externalImages {
        relativePath
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
    }
  }
`;

;

var make = PhotosPage;

var slug = "/photos";

var $$default = PhotosPage;

exports.Styles = Styles;
exports.AlbumUnit = AlbumUnit;
exports.Unexpected = Unexpected;
exports.make = make;
exports.slug = slug;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.frontmatter = frontmatter;
/* topSection Not a pure module */
